.top-countries {
  font-family: "Gotham Book", sans-serif;

  .country {
    max-width: 66px;
    overflow: hidden;
    display: inline-block;
    margin-right: 30px;
    margin-top: 20px;

    &:last-child {
      margin-right: 0;
    }

    .flag {
      width: 38px;
      height: 25px;
      background-size: auto;
    }

    h3 {
      margin-top: 15.5px;
      margin-bottom: 13px;
      font-size: 13px;
    }

    h4 {
      font-size: 13px;
      margin-bottom: 10px;
      margin-top: 0;
    }

    h5 {
      margin: 0;
      font-size: 13px;
    }
  }
}