.sourcing-lists {
  .section-title{
    margin-bottom: 0;
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
    -webkit-box-pack: justify!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important;

    .buttons {
      button {
        background-color: #466A94;

        a {
          color: white;
        }
      }
    }
  }
}