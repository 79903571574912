.recent-news-container {
  border-top: 1px solid #EEEEEE;
  margin-top: 16px;

  table {
    width: 50% !important;
    display: inline-block;

    tbody {
      & th {
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: left !important;
        &:first-child {
          width: 50%;
        }

        &:not(:first-child) {
          width: 50%;
        }
      }
      & tr:nth-child(even) {
        background-color: transparent !important;
      }
    }

    .company-logo {
      display: inline-block;

      .media-body {
        width: auto;

        .media-heading {
          text-align: left;
          font-size: 12px;
          margin-bottom: 0;

          a {
            p {
              display: inline-block;
              margin-bottom: 0;

              &.company-name {
                color: #000;
                font-family: Gotham, sans-serif;
                font-weight: bold;
                font-size: 12px;
                text-transform: uppercase;
              }

              &.recent-news-date {
                color: #555555;
                font-family: Gotham, sans-serif;
                font-weight: normal;
                font-size: 13px;
                margin-left: 18px;
                text-transform: capitalize;
              }
            }
            &:hover{
              .company-name{
                text-decoration: underline;
              }
            }
          }
        }

        a {
          p {
            &.recent-news-title {
              color: #333333;
              font-family: Gotham, sans-serif;
              font-weight: normal;
              font-size: 14px;
              margin-top: 9px;
              margin-bottom: 0;
            }
          }
        }
      }

      .media-left {
        padding-right: 16px;
        vertical-align: middle;
      }
    }
  }
}