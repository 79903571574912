.fixed-table-head {
  overflow-y: auto;
  height: 220px;
  width: 100%;
  margin-top: 16px;

  table {
    border-collapse: collapse;
    width: 100%;

    thead {
      tr {
        background: white;
        position: sticky;
        top: 0;
      }
    }
  }
}
