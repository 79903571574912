.dashboard {
  height: 100%;
  background-color: #EFF0F2;

  .btn {
    margin-right: 5px;
  }

  .list-bubble {
    border: none;
    margin-right: 4px;

    span {
      color: white;
      vertical-align: text-top;
    }
  }

  .dashboard-section {
    background-color: white;
    margin: 20px;
    padding: 20px;

    .section-title {
      display: inline-block;
      font-family: "Gotham Book", serif;
      font-size: 20px;
      font-weight: bold;
      vertical-align: middle;
      margin: 0;
    }
  }

  .mysalesforce-table{
    tbody{
      tr{
        background-color: transparent !important;
         &:focus, &:focus-visible{
           outline: none;
          background-color: #E5E6E6 !important;
        }
        &:active {
          background-color: transparent !important;
        }
      }
    }
  }

  .mysalesforce-container, .recent-news-container{
    height: 405px;
  }
}