.my-watchlists {
  .refresh-watchlist, .settings-watchlist {
    vertical-align: middle;
  }

  .refresh-watchlist {
    margin: 0 16px 0 16px;
    height: 34px;
    width: 34px;
  }

  .tabs {
    float: right;
    border: 0;
    vertical-align: middle;

    a {
      border-radius: 0;
      border: 1px solid #1F3042;
      border-right-width: 0;
      color: rgba(0, 0, 0, 0.5);
      font-family: "Gotham Book", sans-serif;
      font-size: 13px;
      font-weight: bold;
      margin: 0;
      padding: 6px 16px;
      text-align: center;
    }

    li {
      &:hover {
        a {
          border: 1px solid #1F3042;
          border-right-width: 0;
        }
      }

      &.active a {
        background-color: #1F3042;
        border: 1px solid #1F3042;
        color: white;
      }

      &:last-child {
        a:last-child {
          border-right-width: 1px;
        }
      }

      &:first-child a {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
      }
      &:last-child a {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
  }
}

.no-watchlist{
  color: #444444;
  font-family: "Gotham Book", sans-serif;
  font-size: 12px;
  margin-top: 12px;
}
